import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { apiPost } from '../../../../processes/helpers/api';
import { setSession } from '../../../../processes/sessionProcesses';
import { EMRButton } from '../../../Button';
import { saveTheme } from '../../../Frial/signIn';
import { DeviceValidatedIllustration } from '../../assets/DeviceValidated';
import { ContentContainer, TextContainer } from './styles';
import { getThemeByUniversity } from '../../../../utils/getThemeByUniversity';

export function DeviceValidated({ credentials }) {
  const { IES } = getThemeByUniversity();
  const [isLoading, setIsLoading] = useState(false);
  async function handleSignIn() {
    setIsLoading(true);
    apiPost('/api/v1/auth/sign_in', true, true)
      .send({
        email: credentials.email,
        password: credentials.password,
      })
      .then((res) => {
        saveTheme(IES);
        setSession(res);
        window.location = '/';
      });
  }
  return (
    <ContentContainer>
      <DeviceValidatedIllustration />
      <TextContainer>
        <h1>Dispositivo validado com sucesso!</h1>
        <p>
          Você já pode seguir usando nossa plataforma normalmente. Lembre-se que
          ao mudar de rede ou dispositivo a confirmação de segurança será
          solicitada novamente. Em caso de dúvidas, entre em contato com nosso
          suporte. Bons estudos.
        </p>
      </TextContainer>

      <EMRButton onClick={handleSignIn} loading={isLoading} fullSized>
        Continuar estudos
        <FaArrowRight size={20} />
      </EMRButton>
    </ContentContainer>
  );
}
