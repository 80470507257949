import { getCookie } from "cookies-next";
import React, { useEffect, useRef, useState } from "react";
import { apiPost } from "../../../../../../processes/helpers/api";
import { setSession } from "../../../../../../processes/sessionProcesses";
import { EMRButton } from "../../../../../Button";
import { saveTheme } from "../../../../../Frial/signIn";
import { toast } from "../../../../../Toast";
import warning from "../../../../assets/warning.svg";
import { CodeInput } from "../../../../components/CodeInput";
import EmergencyAccessModal from "../../../../components/EmergencyAccessModal";
import { EMERGENCY_ACCESS_MODAL_TYPE } from "../../../../enum";
import {
  ButtonContainer,
  Content,
  Description,
  Paragraph,
  ReturnButton,
  Title,
  WarningContainer,
  WarningText
} from "../../../../styles";
import { generateFingerprint } from "../../../../utils/generateFingerprint";
import { requestEmergencyAccess } from "../../../../utils/requestEmergencyAccess";
import { requestHasEmergencyAccess } from "../../../../utils/requestHasEmergencyAccess";
import { requestIsPrimaryDeviceRegistered } from "../../../../utils/requestIsPrimaryDeviceRegistered";
import { requestRegisterActiveDevice } from "../../../../utils/requestRegisterActiveDevice";
import { requestValidateCode } from "../../../../utils/requestValidateCode";
import { getThemeByUniversity } from "../../../../../../utils/getThemeByUniversity";

export function SecurityConfirmationStep({
  credentials,
  advanceStep,
  handleToAlternativeAccess
}) {
  const generatedFingerprint = generateFingerprint();
  const { IES } = getThemeByUniversity();
  const email = getCookie("email");
  const [codeError, setCodeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasPrimaryDevice, setHasPrimaryDevice] = useState(false);
  const [emergencyAccessModal, setEmergencyAccessModal] = useState({
    isOpened: false,
    modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT
  });
  const [code, setCode] = useState("");

  function handleEmergencyAccessModal() {
    setEmergencyAccessModal((prevState) => ({
      ...prevState,
      isOpened: true
    }));
  }

  function handleHasEmergencyAccess() {
    requestHasEmergencyAccess(credentials.email).then((hasEmergencyAccess) => {
      if (hasEmergencyAccess) {
        setEmergencyAccessModal((prevState) => ({
          ...prevState,
          modalType: EMERGENCY_ACCESS_MODAL_TYPE.ALREADY_ASKED
        }));
      } else {
        setEmergencyAccessModal((prevState) => ({
          ...prevState,
          modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT
        }));
      }
    });
  }

  async function checkHasPrimaryDevice() {
    const result = await requestIsPrimaryDeviceRegistered(email);
    if (result.status === 200) setHasPrimaryDevice(result.body);
  }

  async function handleCodeValidation() {
    setIsLoading(true);
    const response = await requestValidateCode(code);
    if (response.status === 200) {
      const registerDeviceResponse = await requestRegisterActiveDevice(
        generatedFingerprint
      );
      if (registerDeviceResponse.status === 201) await advanceStep();
    } else if (response.status === 401) {
      toast.warning("Você não está com ambos os dispositivos na mesma rede");
    } else {
      toast.error("Código inválido");
      setCodeError("Código inválido");
    }
    setIsLoading(false);
  }

  async function handleToSignInSession() {
    const response = await requestEmergencyAccess(
      credentials.email,
      generatedFingerprint
    );
    if (response.status === 201) {
      apiPost("/api/v1/auth/sign_in", true, true)
        .send({
          email: credentials.email,
          password: credentials.password
        })
        .then((res) => {
          saveTheme(IES);
          setSession(res);
          window.location = "/";
        });
    }
  }

  useEffect(() => {
    checkHasPrimaryDevice();
    handleHasEmergencyAccess();
  }, []);

  return (
    <Content>
      <Description>
        <Title>Confirmação de segurança</Title>
        <Paragraph>
          Para continuar, conclua esta etapa de verificação. Baixe e acesse o
          aplicativo da Eu Médico Residente em seu celular, faça login e em
          ”minha conta” acesse o gerador de códigos de segurança. Ambos os
          dispositivos precisam estar na mesma rede. Adicione o código mais
          recente abaixo:
        </Paragraph>
      </Description>

      <CodeInput
        errorMessage={codeError}
        onFocus={() => {
          setCodeError(null);
        }}
        onChange={(code) => {
          setCode(code);
        }}
      />

      {!hasPrimaryDevice && (
        <WarningContainer>
          <img src={warning} />
          <WarningText>
            Primeiro você precisará cadastrar um smartphone como dispositivo
            principal, através do nosso aplicativo, para poder cadastrar os
            demais dispositivos e garantir a segurança de sua conta.
          </WarningText>
        </WarningContainer>
      )}
      <ButtonContainer>
        <EMRButton
          fullSized
          fontSize={"lg"}
          mediumWeight
          onClick={handleCodeValidation}
          loading={isLoading}
        >
          Confirmar código
        </EMRButton>

        <ReturnButton
          fullSized
          fontSize={"lg"}
          mediumWeight
          mode="transparent"
          onClick={() => {
            if (hasPrimaryDevice) {
              handleEmergencyAccessModal();
            } else {
              handleToAlternativeAccess();
            }
          }}
        >
          {hasPrimaryDevice ? "Acesso emergencial" : "Acessos alternativos"}
        </ReturnButton>
      </ButtonContainer>

      {emergencyAccessModal.isOpened && (
        <EmergencyAccessModal
          isOpened={emergencyAccessModal.isOpened}
          modalType={emergencyAccessModal.modalType}
          handleToSignInSession={handleToSignInSession}
          handleCloseModal={() => {
            setEmergencyAccessModal((prevState) => ({
              ...prevState,
              isOpened: false
            }));
          }}
        />
      )}
    </Content>
  );
}
