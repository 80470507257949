import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiPost } from '../../../../processes/helpers/api';
import { setSession } from '../../../../processes/sessionProcesses';
import { saveTheme } from '../../../Frial/signIn';
import EmergencyAccessModal from '../../components/EmergencyAccessModal';
import {
  ACTIVE_SCREEN,
  CODE_VALIDATION_ACTION_TYPE,
  CODE_VALIDATION_SCREEN_TYPE,
  EMERGENCY_ACCESS_MODAL_TYPE,
} from '../../enum';
import { generateFingerprint } from '../../utils/generateFingerprint';
import { requestEmergencyAccess } from '../../utils/requestEmergencyAccess';
import { requestHasEmergencyAccess } from '../../utils/requestHasEmergencyAccess';
import { requestHasTemporaryAccess } from '../../utils/requestHasTemporaryAccess';
import AlternativeAccessScreen from './';
import { getThemeByUniversity } from '../../../../utils/getThemeByUniversity';

export default function AlternativeAccessScreenContainer({ location }) {
  const credentials = location.state.credentials;
  const generated_fingerprint = generateFingerprint();
  const history = useHistory();
  const { IES } = getThemeByUniversity();
  const [hasTemporaryAccess, setHasTemporaryAccess] = useState(false);
  const [emergencyAccessModal, setEmergencyAccessModal] = useState({
    isOpened: false,
    modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT,
  });

  function handleToTemporaryAccess(temporaryAccessDay) {
    history.replace('/signin-security', {
      activeScreen: ACTIVE_SCREEN.CODE_VALIDATION,
      codeValidationType: CODE_VALIDATION_SCREEN_TYPE.DEFAULT,
      codeValidationActionType: CODE_VALIDATION_ACTION_TYPE.TEMPORARY_ACCESS,
      fingerprint: location.state.fingerprint,
      temporaryAccessDay: temporaryAccessDay,
      email: credentials.email,
      password: credentials.password,
    });
  }

  async function handleToSignInSession() {
    const response = await requestEmergencyAccess(
      credentials.email,
      generated_fingerprint
    );
    if (response.status === 201) {
      apiPost('/api/v1/auth/sign_in', true, true)
        .send({
          email: credentials.email,
          password: credentials.password,
        })
        .then((res) => {
          saveTheme(IES);
          setSession(res);
          window.location = '/';
        });
    }
  }

  function handleHasEmergencyAccess() {
    requestHasEmergencyAccess(credentials.email).then((hasEmergencyAccess) => {
      if (hasEmergencyAccess) {
        setEmergencyAccessModal((prevState) => ({
          ...prevState,
          modalType: EMERGENCY_ACCESS_MODAL_TYPE.ALREADY_ASKED,
        }));
      } else {
        setEmergencyAccessModal((prevState) => ({
          ...prevState,
          modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT,
        }));
      }
    });
  }

  function handleHasTemporaryAccess() {
    requestHasTemporaryAccess(credentials.email).then((hasTemporaryAccess) => {
      if (hasTemporaryAccess) {
        setHasTemporaryAccess(true);
      } else {
        setHasTemporaryAccess(false);
      }
    });
  }

  useEffect(() => {
    handleHasEmergencyAccess();
    handleHasTemporaryAccess();
  }, []);

  return (
    <AlternativeAccessScreen
      handleToTemporaryAccess={handleToTemporaryAccess}
      handleToOpenEmergencyAccessModal={() =>
        setEmergencyAccessModal((prevState) => ({
          ...prevState,
          isOpened: true,
        }))
      }
      hasTemporaryAccess={hasTemporaryAccess}
    >
      {emergencyAccessModal.isOpened && (
        <EmergencyAccessModal
          isOpened={emergencyAccessModal.isOpened}
          modalType={emergencyAccessModal.modalType}
          handleToSignInSession={handleToSignInSession}
          handleCloseModal={() => {
            setEmergencyAccessModal((prevState) => ({
              ...prevState,
              isOpened: false,
            }));
          }}
        />
      )}
    </AlternativeAccessScreen>
  );
}
